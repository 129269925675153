import { ToastContainer, toast } from "react-toastify";

import React, { useState, useEffect, useRef } from "react";
import { ethers } from "ethers";
import io from "socket.io-client";
import { AdjustmentsHorizontalIcon } from "@heroicons/react/24/solid";
import EmojiPicker from "emoji-picker-react";
import SettingsModal from "../SettingsModal";
import BanMuteModal from "../BanMuteModal";
import GifPicker from "../GifPicker";
import gifIcon from "../assets/gif.png";
import emojiIcon from "../assets/laugh.png";
import anonIcon from "../assets/anonimous.png";

import {
  useWeb3Modal,
  useWeb3ModalAccount,
  useWeb3ModalProvider,
} from "@web3modal/ethers/react";
import { BrowserProvider } from "ethers";
import { useContext } from "react";

import { AppContext } from "./../context/appContext";

// Assets
import communityTitle from "../assets/degenerate-community.png";
import arrowUp from "../assets/arrow.png";
import chatBg from "../assets/chatBackground.png";
import Loading from "../components/LoadingAnimation";

const socket = io(process.env.REACT_APP_SERVER_URL);

const AUTHORIZED_WALLETS = process.env.REACT_APP_MODWALLETS.split(",");
console.log("Auth wallets", AUTHORIZED_WALLETS);

function CommunityChat() {
  const [username, setUsername] = useState("");
  const [nameColor, setNameColor] = useState("#000000");
  const [profilePic, setProfilePic] = useState("");
  const [message, setMessage] = useState("");
  const [messageList, setMessageList] = useState([]);
  const [isJoined, setIsJoined] = useState(false);
  const [restrictedMessages, setRestrictedMessages] = useState([]);
  const [restrictedMessage, setRestrictedMessage] = useState("");
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedMessageId, setSelectedMessageId] = useState(null);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [showGifPicker, setShowGifPicker] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [walletAddress, setWalletAddress] = useState("");

  const { isConnected, address } = useWeb3ModalAccount();

  const [isLoading, setIsLoading] = useState(false);

  const { successToast, errorToast, infoToast } = useContext(AppContext);

  // Function to connect wallet using WalletKit
  const loginWallet = async () => {
    if (!isConnected) return errorToast("Wallet Not Connected");

    try {
      setIsLoading(true);
      setWalletAddress(address);
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/users/${address}`
      );
      if (!response.ok) throw new Error(`Server error: ${response.status}`);
      const data = await response.json();

      if (data.username) {
        setUsername(data.username); // Set the username
        setNameColor(data.nameColor || "#000000");
        setProfilePic(data.profilePic || "");
        setIsJoined(true);
        console.log("Username set. Waiting for useEffect to call joinChat...");
        setIsLoading(false);
      } else {
        const enteredUsername = prompt(
          "Enter a username to link to your wallet:"
        );

        if (enteredUsername) {
          await fetch(`${process.env.REACT_APP_SERVER_URL}/users`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              walletAddress: address,
              username: enteredUsername,
            }),
          }).then((response) => {
            console.log(response);
          });
          setUsername(enteredUsername);
          setIsJoined(true);

          successToast("Username created and set");

          console.log(
            "Username created and set. Waiting for useEffect to call joinChat..."
          );
        }
        setIsLoading(false);
      }
    } catch (error) {
      console.error("MetaMask login failed:", error);
      setIsLoading(false);
    }
  };

  const messagesEndRef = useRef(null);
  // Scroll to the bottom of the message list
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  // Call scrollToBottom every time messageList updates
  useEffect(() => {
    scrollToBottom();
  }, [messageList]);

  // Handle emoji selection
  const onEmojiClick = (emojiObject) => {
    setMessage((prevMessage) => prevMessage + emojiObject.emoji);
  };

  const handleGifSelect = (gifUrl) => {
    if (typeof gifUrl === "string" && gifUrl !== "") {
      console.log("Selected GIF URL:", gifUrl); // Add this line
      setMessage((prevMessage) => prevMessage + "" + gifUrl);
      setShowGifPicker(false);
    } else {
      console.error("Invalid gifUrl received:", gifUrl); // Debugging line
    }
  };
  // Check if wallet address is authorized
  const isAuthorizedWallet = AUTHORIZED_WALLETS.includes(
    address?.trim().toLowerCase()
  );

  const handleUserClick = async (msg) => {
    if (isAuthorizedWallet) {
      try {
        // Fetch the full user information based on the address
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/users/${msg.address}`
        );

        console.log("msg,", msg.address);

        if (!response.ok) throw new Error("Failed to fetch user data");

        const userData = await response.json();
        console.log("Fetched user data:", userData); // Log the fetched user data

        // Ensure `author` or `username` is present in the `selectedUser`
        setSelectedUser({
          ...userData,
          author: userData.username, // or author if available directly from userData
          messageId: msg._id, // Keep the message ID for message deletion if needed
        });
        setSelectedMessageId(msg._id);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    }
  };

  const closeBanMuteModal = () => setSelectedUser(null);

  const banUser = async (user) => {
    await fetch(`${process.env.REACT_APP_SERVER_URL}/users/ban`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        author: user.author,
        address: address.trim().toLowerCase(),
      }),
    });
    closeBanMuteModal();
  };

  const deleteMessage = async (messageId) => {
    console.log("mest", messageId, address.trim().toLowerCase());

    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/messages/${messageId}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            address: address.trim().toLowerCase(),
          }),
        }
      );

      if (response.ok) {
        setMessageList((prevMessages) =>
          prevMessages.filter((msg) => msg.id !== messageId)
        );
        console.log("Message deleted successfully on client");
        closeBanMuteModal();
      } else {
        console.error("Failed to delete message on the server");
      }
    } catch (error) {
      console.error("Error deleting message:", error);
    }
  };

  const muteUser = async (user) => {
    console.log("Unmuting user with author:", user.author); // Log author before sending request

    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/users/mute`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            author: user.author,
            address: address.trim().toLowerCase(),
          }),
        }
      );

      if (response.ok) {
        console.log(`User ${user.author} has been muted.`);

        // Update the local `selectedUser` to reflect the new mute status
        setSelectedUser((prevUser) => ({
          ...prevUser,
          muted: true,
        }));

        closeBanMuteModal();
      } else {
        console.error("Failed to mute the user on the server.");
      }
    } catch (error) {
      console.error("Error muting user:", error);
    }
  };

  const unmuteUser = async (user) => {
    console.log("Unmuting user with author:", user.author); // Log author before sending request

    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/users/unmute`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            author: user.author,
            address: address.trim().toLowerCase(),
          }), // Ensure "author" is correctly passed
        }
      );

      if (response.ok) {
        console.log(`User ${user.author} has been unmuted.`);

        // Update the local `selectedUser` to reflect the new unmute status
        setSelectedUser((prevUser) => ({
          ...prevUser,
          muted: false,
        }));

        closeBanMuteModal();
      } else {
        console.error("Failed to unmute the user on the server.");
      }
    } catch (error) {
      console.error("Error unmuting user:", error);
    }
  };

  const joinChat = () => {
    console.log("join chat");

    if (username && address) {
      console.log("Emitting request_join_general with wallet:", address);
      socket.emit("request_join_general", address); // Emit event with address
    } else {
      console.log("joinChat called, but username or address is missing.");
    }
  };

  // useEffect to call joinChat once both address and username are set
  useEffect(() => {
    console.log("data a", username);
    joinChat();

    if (username && isConnected) {
      console.log("Both username and address are set. Calling joinChat...");
      socket.emit("join_restricted_room", address);

      joinChat();
    }
  }, [username, address]);

  useEffect(() => {
    socket.on("restricted_chat_history", (history) => {
      console.log("Received restricted chat history:", history);
      setRestrictedMessages(history);
    });

    socket.on("receive_restricted_message", (message) =>
      setRestrictedMessages((prev) => [...prev, message])
    );

    return () => {
      socket.off("restricted_chat_history");
      socket.off("receive_restricted_message");
    };
  }, []);

  useEffect(() => {
    socket.on("message_deleted", (messageId) => {
      console.log(`Message with ID ${messageId} deleted`);
      setMessageList(
        (prevMessages) => prevMessages.filter((msg) => msg._id !== messageId) // Use _id here as well
      );
    });

    return () => {
      socket.off("message_deleted");
    };
  }, []);

  useEffect(() => {
    // Listen for the remove_user_messages event
    socket.on("remove_user_messages", (bannedAuthor) => {
      console.log(`Removing messages from banned user: ${bannedAuthor}`);
      setMessageList((prevMessages) =>
        prevMessages.filter((msg) => msg.author !== bannedAuthor)
      );
    });

    return () => {
      socket.off("remove_user_messages");
    };
  }, []);

  const sendMessage = () => {
    if (message && isJoined) {
      const messageData = {
        id: Date.now(),
        room: "general",
        author: username,
        address,
        message,
        nameColor,
        profilePic,
        time: new Date().toLocaleTimeString(),
      };

      socket.emit("send_message", messageData);
      setMessage("");
      console.log("sent");
    }
  };

  useEffect(() => {
    socket.on("chat_history", (history) => setMessageList(history));
    socket.on("receive_message", (data) => {
      console.log("receive_message", data);
      setMessageList((prev) => [...prev, data]);
    });

    return () => {
      socket.off("chat_history");
      socket.off("receive_message");
    };
  }, []);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      if (isJoined) {
        sendMessage();
      }
    }
  };

  const sendRestrictedMessage = () => {
    if (!isAuthorizedWallet) {
      alert("You are not authorized to send messages in this chat");
      return;
    }

    const messageData = {
      authorWallet: address,
      message: restrictedMessage,
      time: new Date().toLocaleTimeString(),
    };
    socket.emit("send_restricted_message", messageData);
    setRestrictedMessage("");
  };

  return (
    <div className="min-h-screen overflow-y-auto flex flex-col items-center">
      <ToastContainer />

      {/* Page Title */}
      <img src={communityTitle} alt="" className="mt-5 max-w-[900px] w-full" />

      {/* Main Chat Component */}
      <div className="bg-white max-w-[800px] w-full bottom-0 fixed rounded-t-lg border-[4px] border-b-0 border-black shadow-lg overflow-y-auto">
        <h2 className="text-xl flex bg-gradient-to-r shadow-2xl from-[#BBCDA1] via-[#C5D2AA] to-[#B5CAA7] p-3 items-center justify-between text-start font-thin cursor-pointer">
          Peasant Degenerate Chat
          <div className="flex">
            {/* Pic a prof pic */}
            <button
              onClick={(e) => {
                e.stopPropagation();
                if (!isJoined)
                  return infoToast(
                    "Connect Before Messing With Settings, You Degenerate!"
                  );
                setIsSettingsOpen(true);
              }}
            >
              <AdjustmentsHorizontalIcon className="h-9 w-9" />
            </button>
          </div>
        </h2>

        <div
          style={{ backgroundImage: `url(${chatBg})` }}
          className={`chat-box relative overflow-y-auto bg-cover p-2 bg-green-200 sm:h-[500px] h-[450px]`}
        >
          {!isJoined && (
            <div className="h-full flex flex-col items-center justify-center">
              <h2 className="text-3xl font-thin text-center mb-2">
                Connect to Chat Room
              </h2>

              <button
                onClick={loginWallet}
                className="bg-white text-black group relative m-1 inline-flex cursor-pointer items-center justify-center overflow-hidden rounded hover:border-b-4 hover:border-l-2 border-black px-7 py-4 font-mono shadow-lg"
              >
                <span className="absolute h-0 w-0 bg-gradient-to-br from-[#30F90F] via-[#F6D416] to-[#F84CD6] transition-all duration-200 ease-out group-hover:h-full group-hover:w-full"></span>
                <span className="relative font-bold">Connect to Chat </span>
              </button>
            </div>
          )}

          {isLoading && <Loading />}

          {messageList.map((msg, index) => (
            <div
              key={index}
              className="flex gap-2 items-end mb-1 max-w-[700px]"
            >
              {/* Profile Pic */}
              <div className="min-w-[45px] flex items-center justify-center">
                {msg.profilePic ? (
                  <div className="border bg-gray-400 rounded-full p-1">
                    <img
                      src={msg.profilePic}
                      alt="Profile"
                      className="w-8 h-8 rounded-full"
                    />
                  </div>
                ) : (
                  <div className="bg-white rounded-full p-1">
                    <img
                      src={anonIcon}
                      alt="Profile"
                      className="w-8 h-8 rounded-full"
                    />
                  </div>
                )}
              </div>

              {/* Text Block */}
              <div className="bg-[#ffffffc3] min-w-[200px] break-words break-all flex-wrap shadow-md border border-[#b6c695] rounded-lg rounded-bl-none relative flex p-2 flex-col pb-8">
                <span
                  onClick={() => handleUserClick(msg)}
                  style={{
                    color: msg.nameColor || "#000000",
                    cursor: isAuthorizedWallet ? "pointer" : "default",
                  }}
                  className="font-thin mb-2 truncate sm:max-w-[400px] max-w-[250px]"
                >
                  {msg.author}
                </span>

                {msg.message.endsWith(".gif") ? (
                  <img
                    src={msg.message.trim()}
                    alt="GIF"
                    className="w-24 h-24 object-cover rounded-md ml-2"
                  />
                ) : (
                  <span className="font-sans text-[15px] text-[#000]">
                    {msg.message}
                  </span>
                )}

                {/* Message Time */}
                <span className="absolute font-sans text-[#878787] bottom-1 font-medium right-1 text-[11px]">
                  {msg.time}
                </span>
              </div>
            </div>
          ))}

          <div ref={messagesEndRef} />
        </div>

        {isJoined && (
          <div className="flex h-[50px] gap-2 p-2 w-full ">
            {showEmojiPicker && (
              <div className="emoji-picker absolute right-0 bottom-[60px]">
                <EmojiPicker onEmojiClick={onEmojiClick} />
              </div>
            )}

            {/* EmojiPicker */}
            <button
              className="emoji-button"
              onClick={() => {
                setShowEmojiPicker((prev) => !prev);
              }}
            >
              <img
                src={emojiIcon}
                alt=""
                className="w-[25px] hover:scale-110"
              />
            </button>

            {showGifPicker && (
              <div className="absolute bottom-[50px] right-0 w-64 bg-white p-4 rounded-lg shadow-lg z-50">
                <input
                  type="text"
                  placeholder="Search GIFs"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="w-full p-2 mb-3 border rounded-md border-gray-300"
                />
                <GifPicker
                  searchTerm={searchTerm}
                  onGifSelect={handleGifSelect}
                />
              </div>
            )}

            <button
              className="flex items-center justify-center transition"
              onClick={() => setShowGifPicker((prev) => !prev)}
            >
              <img src={gifIcon} alt="" className="w-[40px] hover:scale-110" />
            </button>

            <input
              type="text"
              placeholder="Type a degenerate message..."
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              onKeyDown={handleKeyDown}
              className="placeholder:font-thin font-thin flex-grow px-4 py-2 border text-[13px] rounded-md"
            />

            <button
              onClick={sendMessage}
              className="bg-green-500 w-[100px] text-[12px] text-white p-2 rounded-md"
            >
              Send It
            </button>
          </div>
        )}
      </div>

      {/* Settings Modal */}
      {isSettingsOpen && (
        <SettingsModal
          onClose={() => setIsSettingsOpen(false)}
          setNameColor={setNameColor}
          setProfilePic={setProfilePic}
          address={address}
        />
      )}

      {selectedUser && (
        <BanMuteModal
          user={selectedUser}
          onClose={closeBanMuteModal}
          onBan={() => banUser(selectedUser)}
          onMute={() => muteUser(selectedUser)}
          onUnmute={() => unmuteUser(selectedUser)}
          onDeleteMessage={deleteMessage}
          messageId={selectedMessageId}
        />
      )}
    </div>
  );
}

export default CommunityChat;

import { ToastContainer, toast } from "react-toastify";

import React, { useState, useEffect, useRef } from "react";
import { ethers } from "ethers";
import io from "socket.io-client";
import { AdjustmentsHorizontalIcon } from "@heroicons/react/24/solid";
import EmojiPicker from "emoji-picker-react";
import SettingsModal from "../SettingsModal";
import BanMuteModal from "../BanMuteModal";
import GifPicker from "../GifPicker";
import anonIcon from "../assets/anonimous.png";

import {
  useWeb3Modal,
  useWeb3ModalAccount,
  useWeb3ModalProvider,
} from "@web3modal/ethers/react";
import { BrowserProvider } from "ethers";
import { useContext } from "react";

import { AppContext } from "../context/appContext";

// Assets
import desktopTitle from "../assets/degenerate-dev-chat.png";
import arrowUp from "../assets/arrow.png";
import chatBg from "../assets/devchat-background.png";

const socket = io(process.env.REACT_APP_SERVER_URL);

const AUTHORIZED_WALLETS = process.env.REACT_APP_OWNER.split(",");

function DevChat() {
  const [username, setUsername] = useState("");
  const [nameColor, setNameColor] = useState("#000000");
  const [profilePic, setProfilePic] = useState("");
  const [message, setMessage] = useState("");
  const [messageList, setMessageList] = useState([]);
  const [isJoined, setIsJoined] = useState(false);
  const [restrictedMessages, setRestrictedMessages] = useState([]);
  const [restrictedMessage, setRestrictedMessage] = useState("");
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedMessageId, setSelectedMessageId] = useState(null);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [showGifPicker, setShowGifPicker] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const { isConnected, address } = useWeb3ModalAccount();

  const [isLoading, setIsLoading] = useState(false);

  // Function to connect wallet using WalletKit
  const loginWallet = async () => {
    if (!isConnected) return errorToast("Connect Metamask");
    try {
      setIsLoading(true);
      console.log("logo");

      // Check if a username is associated with this wallet address in your backend
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/users/${address}`
      );
      if (response.ok) {
        const data = await response.json();
        setUsername(data.username || "New User");
        setNameColor(data.nameColor || "#000000");
        setProfilePic(data.profilePic || "");
        setIsJoined(true);
      } else {
        const enteredUsername = prompt(
          "Enter a username to link to your wallet:"
        );
        if (enteredUsername) {
          await fetch(`${process.env.REACT_APP_SERVER_URL}/users`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              address: address,
              username: enteredUsername,
            }),
          });
          setUsername(enteredUsername);
          setIsJoined(true);
        }
      }

      setIsLoading(false);
      console.log("logo 2");
    } catch (error) {
      console.log("logo 3");

      console.error("Wallet connection failed:", error);
      toast.error("Wallet connection failed");
      setIsLoading(false);
    }
  };

  // Call scrollToBottom every time messageList or restrictedMessages updates
  useEffect(() => {
    scrollToBottom();
  }, [messageList, restrictedMessages]);

  // Check if wallet address is authorized
  const isAuthorizedWallet = AUTHORIZED_WALLETS.includes(address);

  console.log("AUTHORIZED_WALLETS", isAuthorizedWallet, AUTHORIZED_WALLETS);

  const handleUserClick = async (msg) => {
    if (isAuthorizedWallet) {
      try {
        // Fetch the full user information based on the address
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/users/${msg.address}`
        );
        if (!response.ok) throw new Error("Failed to fetch user data");

        const userData = await response.json();
        console.log("Fetched user data:", userData); // Log the fetched user data

        // Ensure `author` or `username` is present in the `selectedUser`
        setSelectedUser({
          ...userData,
          author: userData.username, // or author if available directly from userData
          messageId: msg._id, // Keep the message ID for message deletion if needed
        });
        setSelectedMessageId(msg._id);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    }
  };

  const closeBanMuteModal = () => setSelectedUser(null);

  const banUser = async (user) => {
    await fetch(`${process.env.REACT_APP_SERVER_URL}/users/ban`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        author: user.author,
        address: address.trim().toLowerCase(),
      }),
    });
    closeBanMuteModal();
  };

  const deleteMessage = async (messageId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/messages/${messageId}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            address: address.trim().toLowerCase(),
          }),
        }
      );

      if (response.ok) {
        setMessageList((prevMessages) =>
          prevMessages.filter((msg) => msg.id !== messageId)
        );
        console.log("Message deleted successfully on client");
        closeBanMuteModal();
      } else {
        console.error("Failed to delete message on the server");
      }
    } catch (error) {
      console.error("Error deleting message:", error);
    }
  };

  const muteUser = async (user) => {
    console.log("Unmuting user with author:", user.author); // Log author before sending request

    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/users/mute`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            author: user.author,
            address: address.trim().toLowerCase(),
          }),
        }
      );

      if (response.ok) {
        console.log(`User ${user.author} has been muted.`);

        // Update the local `selectedUser` to reflect the new mute status
        setSelectedUser((prevUser) => ({
          ...prevUser,
          muted: true,
        }));

        closeBanMuteModal();
      } else {
        console.error("Failed to mute the user on the server.");
      }
    } catch (error) {
      console.error("Error muting user:", error);
    }
  };

  const messagesEndRef = useRef(null);

  const unmuteUser = async (user) => {
    console.log("Unmuting user with author:", user.author); // Log author before sending request

    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/users/unmute`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            author: user.author,
            address: address.trim().toLowerCase(),
          }), // Ensure "author" is correctly passed
        }
      );

      if (response.ok) {
        console.log(`User ${user.author} has been unmuted.`);

        // Update the local `selectedUser` to reflect the new unmute status
        setSelectedUser((prevUser) => ({
          ...prevUser,
          muted: false,
        }));

        closeBanMuteModal();
      } else {
        console.error("Failed to unmute the user on the server.");
      }
    } catch (error) {
      console.error("Error unmuting user:", error);
    }
  };

  const joinChat = () => {
    console.log("join chat");

    if (username && address) {
      console.log("Emitting request_join_general with wallet:", address);
      socket.emit("request_join_general", address); // Emit event with address
    } else {
      console.log("joinChat called, but username or address is missing.");
    }
  };

  // useEffect to call joinChat once both address and username are set
  useEffect(() => {
    console.log("data a");

    joinChat();

    if (username && isConnected) {
      console.log("Both username and address are set. Calling joinChat...");
      socket.emit("join_restricted_room", address);

      joinChat();
    }
  }, [username, address]);

  useEffect(() => {
    socket.on("restricted_chat_history", (history) => {
      console.log("Received restricted chat history:", history);
      setRestrictedMessages(history);
    });

    socket.on("receive_restricted_message", (message) =>
      setRestrictedMessages((prev) => [...prev, message])
    );

    return () => {
      socket.off("restricted_chat_history");
      socket.off("receive_restricted_message");
    };
  }, []);

  useEffect(() => {
    socket.on("message_deleted", (messageId) => {
      console.log(`Message with ID ${messageId} deleted`);
      setMessageList(
        (prevMessages) => prevMessages.filter((msg) => msg._id !== messageId) // Use _id here as well
      );
    });

    return () => {
      socket.off("message_deleted");
    };
  }, []);

  useEffect(() => {
    // Listen for the remove_user_messages event
    socket.on("remove_user_messages", (bannedAuthor) => {
      console.log(`Removing messages from banned user: ${bannedAuthor}`);
      setMessageList((prevMessages) =>
        prevMessages.filter((msg) => msg.author !== bannedAuthor)
      );
    });

    return () => {
      socket.off("remove_user_messages");
    };
  }, []);

  const sendMessage = () => {
    if (message && isJoined) {
      const messageData = {
        id: Date.now(),
        room: "general",
        author: username,
        address,
        message,
        nameColor,
        profilePic,
        time: new Date().toLocaleTimeString(),
      };

      socket.emit("send_message", messageData);
      setMessage("");
      console.log("sent");
    }
  };

  useEffect(() => {
    scrollToBottom();
    socket.on("chat_history", (history) => setMessageList(history));
    socket.on("receive_message", (data) => {
      console.log("receive_message", data);
      setMessageList((prev) => [...prev, data]);
    });

    return () => {
      socket.off("chat_history");
      socket.off("receive_message");
    };
  }, []);

  const sendRestrictedMessage = () => {
    if (!isAuthorizedWallet) {
      alert("You are not authorized to send messages in this chat");
      return;
    }

    const messageData = {
      authorWallet: address,
      message: restrictedMessage,
      time: new Date().toLocaleTimeString(),
    };
    socket.emit("send_restricted_message", messageData);

    setRestrictedMessage("");
    console.log("Called Send restricted message");
  };

  const alertToast = (text) => {
    return toast(text, {
      position: "top-left",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      type: "warning",
    });
  };

  const errorToast = (text) => {
    return toast(text, {
      position: "top-left",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      type: "error",
    });
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="min-h-screen bg-gradient-to-br overflow-y-auto flex flex-col items-center">
      <ToastContainer />

      {/* Page Title */}
      <img src={desktopTitle} alt="" className="mt-5 max-w-[900px] w-full" />

      {/* Main Content */}
      <div className="flex flex-col fixed bottom-0 w-full max-w-[900px]">
        {/* Dev Chat Component */}
        <div className="rounded-t-lg border-[4px] border-b-0 bg-[#42FD0E] border-black shadow-lg w-full">
          <h2 className="text-xl bg-gradient-to-br  flex items-center p-3 justify-between text-start font-thin cursor-pointer">
            Dev Degenerate Messages
          </h2>

          <div className="flex flex-col relative transition-all ease-out duration-300 overflow-hidden sm:h-[500px] h-[450px]">
            <div
              style={{ backgroundImage: `url(${chatBg})` }}
              className="bg-cover overflow-y-auto restricted-chat-box bg-[#e6e6e6] w-full absolute h-full"
            >
              {restrictedMessages.map((msg, index) => (
                <div
                  key={index}
                  className="flex gap-2 items-end mb-1 max-w-[700px]"
                >
                  {/* Profile Pic */}
                  <div className="min-w-[45px] flex items-center justify-center">
                    {msg.profilePic ? (
                      <div className="border bg-gray-400 rounded-full p-1">
                        <img
                          src={msg.profilePic}
                          alt="Profile"
                          className="w-8 h-8 rounded-full"
                        />
                      </div>
                    ) : (
                      <div className="bg-white rounded-full p-1">
                        <img
                          src={anonIcon}
                          alt="Profile"
                          className="w-8 h-8 rounded-full"
                        />
                      </div>
                    )}
                  </div>

                  {/* Text Block */}
                  <div className="bg-[#ffffffc3] min-w-[200px] break-words break-all flex-wrap shadow-md border border-[#b6c695] rounded-lg rounded-bl-none relative flex p-2 flex-col pb-8">
                    <span
                      onClick={() => handleUserClick(msg)}
                      style={{
                        color: msg.nameColor || "#000000",
                        cursor: isAuthorizedWallet ? "pointer" : "default",
                      }}
                      className="font-thin mb-2 truncate max-w-[400px]"
                    >
                      DEV
                    </span>

                    {msg.message.endsWith(".gif") ? (
                      <img
                        src={msg.message.trim()}
                        alt="GIF"
                        className="w-24 h-24 object-cover rounded-md ml-2"
                      />
                    ) : (
                      <span className="font-sans text-[15px] text-[#000]">
                        {msg.message}
                      </span>
                    )}

                    {/* Message Time */}
                    <span className="absolute font-sans text-[#878787] bottom-1 font-medium right-1 text-[11px]">
                      {msg.time}
                    </span>
                  </div>
                </div>
              ))}

              {!isJoined && (
                <div className="w-full h-full flex items-center justify-center">
                  {isAuthorizedWallet ? (
                    <button
                      onClick={loginWallet}
                      className="bg-white text-black group relative m-1 inline-flex cursor-pointer items-center justify-center overflow-hidden rounded hover:border-b-4 hover:border-l-2 border-black px-7 py-4 font-mono shadow-lg"
                    >
                      <span className="absolute h-0 w-0 bg-gradient-to-br from-[#30F90F] via-[#F6D416] to-[#F84CD6] transition-all duration-200 ease-out group-hover:h-full group-hover:w-full"></span>
                      <span className="relative font-bold">Loggin Dev</span>
                    </button>
                  ) : (
                    <button
                      onClick={loginWallet}
                      className="bg-white text-black group relative m-1 inline-flex cursor-pointer items-center justify-center overflow-hidden rounded hover:border-b-4 hover:border-l-2 border-black px-7 py-4 font-mono shadow-lg"
                    >
                      <span className="absolute h-0 w-0 bg-gradient-to-br from-[#30F90F] via-[#F6D416] to-[#F84CD6] transition-all duration-200 ease-out group-hover:h-full group-hover:w-full"></span>
                      <span className="relative font-bold">
                        Display Messages
                      </span>
                    </button>
                  )}
                </div>
              )}

              {/* Scroll to bottom reference element */}
              <div ref={messagesEndRef} />
            </div>
          </div>

          {isAuthorizedWallet && isJoined && (
            <div className="flex gap-1 bg-white ">
              <input
                type="text"
                value={restrictedMessage}
                onChange={(e) => setRestrictedMessage(e.target.value)}
                placeholder="Type a message..."
                className="flex-grow px-4 py-2 border rounded-md"
              />
              <button
                onClick={sendRestrictedMessage}
                className="bg-green-500 text-white px-4 py-2 rounded-md"
              >
                Send
              </button>
            </div>
          )}
        </div>
      </div>

      {/* Settings Modal */}
      {isSettingsOpen && (
        <SettingsModal
          onClose={() => setIsSettingsOpen(false)}
          setNameColor={setNameColor}
          setProfilePic={setProfilePic}
          address={address}
        />
      )}

      {selectedUser && (
        <BanMuteModal
          user={selectedUser}
          onClose={closeBanMuteModal}
          onBan={() => banUser(selectedUser)}
          onMute={() => muteUser(selectedUser)}
          onUnmute={() => unmuteUser(selectedUser)}
          onDeleteMessage={deleteMessage}
          messageId={selectedMessageId}
        />
      )}
    </div>
  );
}

export default DevChat;

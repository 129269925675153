import React from "react";

const BanMuteModal = ({
  user,
  onClose,
  onBan,
  onMute,
  onUnmute,
  onDeleteMessage,
}) => {
  const handleBan = () => {
    onBan(user.walletAddress);
    onClose();
  };

  const handleMute = () => {
    onMute(user.walletAddress);
    onClose();
  };

  const handleUnmute = () => {
    onUnmute(user.walletAddress);
    onClose();
  };

  return (
    <div className="fixed inset-0 bg-gray-500 bg-opacity-50 flex items-center justify-center">
      <div className="bg-white p-4 rounded-md shadow-md">
        <h2 className="text-lg font-semibold mb-4">Manage User</h2>
        <p>Actions for {user.username}:</p>

        {/* Delete Message Button */}
        <button
          onClick={() => onDeleteMessage(user.messageId)}
          className="bg-blue-500 text-white p-2 mt-2 rounded"
        >
          Delete Message
        </button>

        {/* Mute/Unmute Button */}
        {user.muted && !user.blacklisted ? (
          <button
            onClick={() => {
              console.log("Unmuting user:", user); // Log the user data
              onUnmute(user);
            }}
            className="bg-green-500 text-white p-2 mt-2 rounded"
          >
            Unmute User
          </button>
        ) : (
          <button
            onClick={() => {
              console.log("Muting user:", user); // Log the user data
              onMute(user);
            }}
            className="bg-yellow-500 text-white p-2 mt-2 rounded"
          >
            Mute User
          </button>
        )}
        {/* Ban User Button */}
        <button
          onClick={handleBan}
          className="bg-red-500 text-white p-2 mt-2 rounded"
        >
          Ban User
        </button>

        {/* Close/Cancel Button */}
        <button onClick={onClose} className="bg-gray-300 p-2 mt-2 rounded">
          Cancel
        </button>
      </div>
    </div>
  );
};

export default BanMuteModal;

import React, { useState, useEffect } from "react";

const API_KEY = process.env.GIPHY_API_KEY; // Replace with your Giphy API key
const clientkey = "my_test_app";

const GifPicker = ({ searchTerm, onGifSelect }) => {
  const [gifs, setGifs] = useState([]);

  useEffect(() => {
    const query = searchTerm || "trending"; // Default search term if empty
    fetch(
      `https://tenor.googleapis.com/v2/search?q=${query}&key=AIzaSyD1Liax22i7c4Bs4YHkFu6BVPuJiRFzMSA&client_key=${clientkey}&limit=10`
    )
      .then((response) => response.json())
      .then((data) => setGifs(data.results))
      .catch((error) => console.error("Error fetching GIFs:", error));
  }, [searchTerm]);

  return (
    <div className="grid grid-cols-3 gap-2">
      {gifs?.map((gif, index) => (
        <img
          key={index}
          src={gif.media_formats.gif.url}
          alt="GIF"
          className="w-24 h-24 object-cover rounded-md cursor-pointer hover:opacity-80 transition"
          onClick={() => onGifSelect(gif.media_formats.gif.url || "")} // Send URL or empty string
        />
      ))}
    </div>
  );
};

export default GifPicker;

import React, { useState, useEffect, useRef } from "react";
import cat1 from "./assets/cat1.png";
import cat2 from "./assets/cat2.png";
import catAudio from "./assets/0008368.mp3";
import arrowUp from "./assets/arrowUp.png";
import table from "./assets/table.png";

const Main = () => {
  const [userCountry, setUserCountry] = useState(""); // State to hold the detected country
  const [countryCode, setCountryCode] = useState(""); // State to hold the detected country code
  const [flagUrl, setFlagUrl] = useState(""); // State to hold the URL for the country's flag
  const [clickCount, setClickCount] = useState(0); // State to hold the click count
  const [leaderboard, setLeaderboard] = useState([]); // State to hold leaderboard data
  const [showLeaderboard, setShowLeaderboard] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [dryCat, setDryCat] = useState(true); // State to manage cat image
  const [animateText, setAnimateText] = useState(false); // State to trigger animation
  const isTouchRef = useRef(false); // Ref to track touch event

  useEffect(() => {
    detectUserCountry();
    fetchLeaderboard();
    setClickCount(Number(localStorage.getItem("user_clicks")) || 0);
  }, []);

  useEffect(() => {
    if (!dryCat) {
      setAnimateText(true);
      setTimeout(() => {
        setAnimateText(false);
      }, 150); // Reset the animation after 150ms
    }
  }, [dryCat]);

  // Function to detect the user's country from the browser's locale settings
  const detectUserCountry = async () => {
    try {
      const request = await fetch(
        "https://api.ipdata.co/?api-key=540362f253ec3637e581ec885d3fff2b8c9c4927bcc8f4fc844ce9b7"
      );
      const jsonResponse = await request.json();

      localStorage.setItem("country", jsonResponse.country_name);
      localStorage.setItem("country_code", jsonResponse.country_code);

      setCountryCode(jsonResponse.country_code);
      setUserCountry(jsonResponse.country_name);
    } catch (error) {
      console.error("Error detecting country: ", error);
      setUserCountry("Unknown");
    }
  };

  // Fetch the leaderboard from the backend
  const fetchLeaderboard = async () => {
    try {
      const response = await fetch(
        "https://popcats-backend.onrender.com/leaderboard"
      );
      const data = await response.json();

      const localTotalCount = data.reduce(
        (total, item) => total + item.count,
        0
      );
      setTotalCount(localTotalCount);
      setLeaderboard(data);
    } catch (error) {
      console.error("Error fetching leaderboard: ", error);
    }
  };

  // Function to handle click logic
  const handleClick = async () => {
    try {
      await fetch("https://popcats-backend.onrender.com/click", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ countryCode, countryName: userCountry }),
      });
      fetchLeaderboard();
    } catch (error) {
      console.error("Error sending click data: ", error);
    }

    setClickCount((prevCount) => {
      const newCount = prevCount + 1;
      localStorage.setItem("user_clicks", newCount);
      return newCount;
    });
  };

  const handleTouchStart = () => {
    console.log("Touch Start");

    isTouchRef.current = true; // Mark it as a touch event
    setDryCat(false); // Set cat2 when touch starts
    const audio = new Audio(catAudio);
    audio.play();
  };

  const handleTouchEnd = () => {
    console.log("Touch End");

    setDryCat(true); // Set back to cat1 when touch ends
    if (isTouchRef.current) {
      handleClick(); // Register the click only for touch
    }

    // Reset the touch flag with a small delay to prevent mouse events from triggering
    setTimeout(() => {
      isTouchRef.current = false;
    }, 5); // Slight delay to prevent immediate mouse events
  };

  const handleMouseDown = (e) => {
    console.log("Mouse Down");

    // Prevent mouse down if it's a touch event
    if (!isTouchRef.current) {
      const audio = new Audio(catAudio);
      audio.play();
      setDryCat(false); // Set to cat2 when pressing down
    }
  };

  const handleMouseUp = (e) => {
    console.log("Mouse Up");

    // Prevent mouse up if it's a touch event
    if (!isTouchRef.current) {
      setDryCat(true); // Set back to cat1 when mouse is released
      handleClick(); // Register the click
    }
  };

  return (
    <div
      className="select-none w-full h-screen bg-cover bg-center"
      onMouseDown={handleMouseDown} // Mouse press down
      onMouseUp={handleMouseUp} // Mouse release
      onTouchStart={handleTouchStart} // Touch press down (changes cat image but does not trigger click)
      onTouchEnd={handleTouchEnd} // Touch release (registers the click)
    >
      <div className="flex flex-col fixed w-full z-[100] items-center justify-center pt-20">
        <div className="flex flex-col md:flex-row items-center justify-between w-full max-w-[1200px]">
          <button
            onClick={(event) => {
              event.stopPropagation(); // Prevent click event from bubbling to parent
            }}
            onMouseDown={(event) => event.stopPropagation()} // Prevents triggering handleMouseDown
            onMouseUp={(event) => event.stopPropagation()} // Prevents triggering handleMouseUp
            onTouchStart={(event) => event.stopPropagation()} // Prevents triggering touch events
            onTouchEnd={(event) => event.stopPropagation()} // Prevents triggering touch events
          >
            {/* <img src={buyIcon} alt="" className="w-[140px]" /> */}
          </button>

          {/* <img src={titleText} alt="Title" className="w-[500px]" /> */}

          <div
            onClick={(event) => {
              event.stopPropagation(); // Prevent click event from bubbling to parent
            }}
            onMouseDown={(event) => event.stopPropagation()} // Prevents triggering handleMouseDown
            onMouseUp={(event) => event.stopPropagation()} // Prevents triggering handleMouseUp
            onTouchStart={(event) => event.stopPropagation()} // Prevents triggering touch events
            onTouchEnd={(event) => event.stopPropagation()} // Prevents triggering touch events
            className="flex items-center gap-2"
          >
            {/* <img src={twitter} alt="" className="w-[40px]" />
            <img src={dexcreener} alt="" className="w-[40px]" />
            <img src={telegram} alt="" className="w-[40px]" /> */}
          </div>
        </div>

        <h2
          className={`font-bold leading-[70px] text-[70px] text-[#2BE101] text-stroke-black transform transition-transform duration-150 ease-in-out ${
            animateText ? "scale-110 rotate-6" : ""
          }`}
          style={{
            textShadow: "4px 4px 6px rgba(0, 0, 0, 0.6)", // Adjust values as needed
          }}
        >
          {clickCount}
        </h2>
      </div>

      <div className="flex flex-col fixed w-full justify-center bottom-0 items-center">
        <img
          onContextMenu={(e) => e.preventDefault()}
          src={dryCat ? cat1 : cat2}
          alt="Cat"
          className="w-[700px] -mb-10 z-[2]"
        />
        <img src={table} alt="" className="w-full h-[120px] z-[1]" />
      </div>

      {/* Sliding panel */}
      <div
        style={{ backgroundImage: table }}
        className="flex text-white w-full justify-center"
      >
        <div
          className={`bg-gradient-to-b font-thin from-[#2BE101] via-[#F5CF1F] border-2 shadow-xl border-black to-[#FB54CF] text-black overflow-y-scroll z-[1000] fixed max-w-[600px] w-full transition-transform duration-300 transform ${
            showLeaderboard ? "translate-y-0" : "translate-y-[calc(100%-40px)]"
          } py-2 rounded-b rounded-[10px] px-2 shadow-lg`}
          style={{
            height: "calc(100vh - 80px)",
            bottom: "0",
          }}
          onClick={(event) => {
            event.stopPropagation(); // Prevent click event from bubbling to parent
          }}
          onMouseDown={(event) => event.stopPropagation()} // Prevents triggering handleMouseDown
          onMouseUp={(event) => event.stopPropagation()} // Prevents triggering handleMouseUp
          onTouchStart={(event) => event.stopPropagation()} // Prevents triggering touch events
          onTouchEnd={(event) => event.stopPropagation()} // Prevents triggering touch events
        >
          <div
            onClick={() => setShowLeaderboard((prevState) => !prevState)}
            className="h-[30px] px-1 flex justify-between items-center"
          >
            <div className="flex items-center">
              <h2>🏆</h2>
              <span className="border border-[#000] mx-5 h-[20px]"></span>
            </div>

            {!showLeaderboard && (
              <div className="flex w-full justify-between items-center">
                <div className="flex gap-2 items-center">
                  <span>#1</span>
                  <img
                    src={`https://flagcdn.com/${leaderboard[0]?.countryCode.toLowerCase()}.svg`}
                    alt={`${userCountry} flag`}
                    className="w-[20px]"
                  />
                  <span>{leaderboard[0]?.countryName}</span>
                  <div className="">{leaderboard[0]?.count}</div>
                </div>
                <div className="flex gap-2">
                  <img
                    src={`https://flagcdn.com/${countryCode?.toLowerCase()}.svg`}
                    alt=""
                    className="w-[20px]"
                  />
                  <div className="">
                    {
                      leaderboard.find(
                        (item) => item.countryName === userCountry
                      )?.count
                    }
                  </div>
                </div>
              </div>
            )}
            {showLeaderboard && (
              <div className="flex w-full justify-center items-center">
                Degenboard
              </div>
            )}

            <div className="flex gap-2 items-center">
              <span className="border border-[#000] mx-3 h-[20px]"></span>
              <img
                src={arrowUp}
                alt="Arrow"
                className={`${
                  showLeaderboard ? "rotate-180" : ""
                } w-[15px] transition-all ease-in-out duration-500`}
              />
            </div>
          </div>

          <div className="mt-4 w-full border-b py-1 border-[#e1e1e1] flex items-center justify-between">
            <div className="flex items-center gap-3">
              <h3 className="font-nunito ml-2 w-[30px] flex justify-center items-center">
                🌍
              </h3>
              <span>Worldwide</span>
            </div>
            <h3>{totalCount}</h3>
          </div>

          <div className="flex">
            <ul className="w-full mt-2 flex flex-col gap-1">
              {leaderboard.map((country, index) => (
                <li
                  key={index}
                  className="w-full border-b py-1 border-[#e1e1e1] flex items-center justify-between"
                >
                  <div className="items-center flex gap-3">
                    <h3 className="font-nunito ml-2 w-[30px] flex justify-center items-center">
                      {index + 1 === 1
                        ? "🥇"
                        : index + 1 === 2
                        ? "🥈"
                        : index + 1 === 3
                        ? "🥉"
                        : index + 1}
                    </h3>
                    <img
                      src={`https://flagcdn.com/${country.countryCode.toLowerCase()}.svg`}
                      alt=""
                      className="w-[25px]"
                    />
                    <span>{country.countryName}</span>
                  </div>
                  <h3>{country.count}</h3>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Main;

import React, { useContext, useEffect } from "react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import {
  createWeb3Modal,
  defaultConfig,
  useWeb3ModalProvider,
} from "@web3modal/ethers/react";
import Main from "./Main";
import CommunityChat from "./pages/CommunityChat";
import DevChat from "./pages/DevChat";
import { ethMainnet } from "./constants/chains";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BrowserProvider } from "ethers";
import { AppContext } from "./context/appContext";
import Header from "./components/Header";
import "./index.css"; // Import your global CSS

function App() {
  const { isLoading, setUserSigner, isConnected, address } =
    useContext(AppContext);

  // 1. Get projectId at https://cloud.walletconnect.com
  const projectId = process.env.REACT_APP_WALLETCONNECT_PROJECT_ID;

  // 3. Create modal
  const metadata = {
    name: "My Website",
    description: "My Website description",
    url: "https://mywebsite.com",
    icons: ["https://avatars.mywebsite.com/"],
  };

  createWeb3Modal({
    ethersConfig: defaultConfig({ metadata }),
    chains: [ethMainnet],
    projectId,
  });

  const { walletProvider } = useWeb3ModalProvider();

  const getSigner = async () => {
    const provider = new BrowserProvider(walletProvider);
    const signer = await provider.getSigner();
    setUserSigner(signer);
  };

  useEffect(() => {
    if (!isConnected) return;
    getSigner();
  }, [address, isConnected]);

  return (
    <BrowserRouter>
      <BackgroundWrapper>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />

        <Header />

        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/CommunityChat" element={<CommunityChat />} />
          <Route path="/DevChat" element={<DevChat />} />
        </Routes>
      </BackgroundWrapper>
    </BrowserRouter>
  );
}

function BackgroundWrapper({ children }) {
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/") {
      document.body.classList.add("image-background");
      document.body.classList.remove("gradient-background");
    } else {
      document.body.classList.add("gradient-background");
      document.body.classList.remove("image-background");
    }
  }, [location.pathname]);

  return children;
}

export default App;

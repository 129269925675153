import {
  useWeb3Modal,
  useWeb3ModalAccount,
  useWeb3ModalProvider,
} from "@web3modal/ethers/react";
import logo from "../assets/degenerate-g.png";
import { useContext } from "react";
import { AppContext } from "../context/appContext";
import { BrowserProvider } from "ethers";
import { NavLink } from "react-router-dom";

import twitterLogo from "../assets/Logo1x.png";
import telegram from "../assets/telegram.png";
import dext from "../assets/dex.png";
import uni from "../assets/uniswap.png";

const Header = () => {
  const { open } = useWeb3Modal();
  const { isConnected, address } = useWeb3ModalAccount();

  const { walletProvider } = useWeb3ModalProvider();
  const { setUserSigner } = useContext(AppContext);

  const connectWallet = async () => {
    try {
      open();
      const provider = new BrowserProvider(walletProvider);
      const signer = await provider.getSigner();
      setUserSigner(signer);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="w-full flex justify-center flex-col items-center">
      <div className="px-2 w-full py-2 flex items-center justify-between max-w-screen-xl">
        <NavLink to="./">
          <img src={logo} alt="" className="sm:w-[200px] w-[100px]" />
        </NavLink>

        <div className="flex gap-2">
          <div className=" hidden md:flex items-center justify-center gap-2">
            <NavLink
              className={({ isActive }) =>
                isActive
                  ? "text-[#FB5AC6] text-center flex items-center justify-center"
                  : "text-[#ffffff] text-center flex items-center justify-center"
              }
              to="./"
            >
              <span>Clicker</span>
            </NavLink>
            <NavLink
              className={({ isActive }) =>
                isActive
                  ? "text-[#FB5AC6] text-center flex items-center justify-center"
                  : "text-[#ffffff] text-center flex items-center justify-center"
              }
              to="./CommunityChat"
            >
              <span>Community</span>
            </NavLink>
            <NavLink
              className={({ isActive }) =>
                isActive
                  ? "text-[#FB5AC6] text-center flex items-center justify-center"
                  : "text-[#ffffff] text-center flex items-center justify-center"
              }
              to="./DevChat"
            >
              <span>Dev Chat</span>
            </NavLink>
          </div>

          <button
            onClick={connectWallet}
            className="border-2 text-[13px] rounded-md bg-white p-2"
          >
            {isConnected
              ? address.slice(0, 7) + "..." + address.slice(-7)
              : "Connect Wallet"}
          </button>
        </div>
      </div>

      {/* Mobile Header */}
      <div className="flex md:hidden py-3 justify-around items-center ustify-center gap-2">
        <NavLink
          className={({ isActive }) =>
            isActive
              ? "text-[#FB5AC6] text-center flex items-center justify-center"
              : "text-[#ffffff] text-center flex items-center justify-center"
          }
          to="./"
        >
          <span>Clicker</span>
        </NavLink>
        <NavLink
          className={({ isActive }) =>
            isActive
              ? "text-[#FB5AC6] text-center flex items-center justify-center"
              : "text-[#ffffff] text-center flex items-center justify-center"
          }
          to="./CommunityChat"
        >
          <span>Community</span>
        </NavLink>
        <NavLink
          className={({ isActive }) =>
            isActive
              ? "text-[#FB5AC6] text-center flex items-center justify-center"
              : "text-[#ffffff] text-center flex items-center justify-center"
          }
          to="./DevChat"
        >
          <span>Dev Chat</span>
        </NavLink>
      </div>

      <div className="flex gap-2">
        <a href="https://x.com/Degen_on_ETH" target="_blank" rel="noreferrer">
          <img src={twitterLogo} alt="" className="w-[40px]" />
        </a>
        <a
          href="https://www.dextools.io/app/en/ether/pair-explorer/0xf73cbb5d8c8f8707ae82c92881ba79ab022a9c75?t=1731279908007"
          target="_blank"
          rel="noreferrer"
        >
          <img src={dext} alt="" className="w-[40px]" />
        </a>
        <a href="https://t.me/Degen_on_ETH" target="_blank" rel="noreferrer">
          <img src={telegram} alt="" className="w-[40px]" />
        </a>

        <a
          href="https://app.uniswap.org/explore/tokens/ethereum/0x8112bdff46299ec58a4879d0888358ae3fc1f438"
          target="_blank"
          rel="noreferrer"
        >
          <img src={uni} alt="" className="w-[40px]" />
        </a>
      </div>
    </div>
  );
};

export default Header;

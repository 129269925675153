import { createContext, useEffect, useState } from "react";
import { toast } from "react-toastify";

export const AppContext = createContext();

const ContextProvider = ({ children }) => {
  const [userSigner, setUserSigner] = useState();

  const successToast = (msg) => {
    toast.success(msg, {
      position: "bottom-left",
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  };

  const errorToast = (msg) => {
    toast.error(msg, {
      position: "bottom-left",
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  };

  const infoToast = (msg) => {
    toast.info(msg, {
      position: "bottom-left",
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  };

  return (
    <AppContext.Provider
      value={{
        successToast,
        errorToast,
        infoToast,

        userSigner,
        setUserSigner,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default ContextProvider;

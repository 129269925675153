import React, { useContext, useState } from "react";
import pfp1 from "./assets/pfp1.png";
import pfp2 from "./assets/pfp2.png";
import pfp3 from "./assets/pfp3.png";
import pfp4 from "./assets/pfp4.png";
import pfp5 from "./assets/pfp5.png";
import pfp6 from "./assets/pfp6.png";
import { AppContext } from "./context/appContext";

function SettingsModal({
  onClose,
  setNameColor,
  setProfilePic,
  walletAddress,
}) {
  const [localNameColor, setLocalNameColor] = useState("#000000");
  const [localProfilePic, setLocalProfilePic] = useState("");
  const [selectedPreset, setSelectedPreset] = useState(null);

  const { successToast } = useContext(AppContext);

  const presetImages = [pfp1, pfp2, pfp3, pfp4, pfp5, pfp6];

  // Convert uploaded image to base64
  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setLocalProfilePic(reader.result);
      setSelectedPreset(null); // Deselect any preset
    };
    reader.readAsDataURL(file);
  };

  // Convert preset image to base64 and highlight it
  const handlePresetImageSelect = async (image, index) => {
    const response = await fetch(image);
    const blob = await response.blob();

    const reader = new FileReader();
    reader.onloadend = () => {
      setLocalProfilePic(reader.result);
      setSelectedPreset(index);
    };
    reader.readAsDataURL(blob);
  };

  const saveSettings = async () => {
    setNameColor(localNameColor);
    setProfilePic(localProfilePic);

    const payload = JSON.stringify({
      walletAddress,
      nameColor: localNameColor,
      profilePic: localProfilePic,
    });

    try {
      await fetch(`${process.env.REACT_APP_SERVER_URL}/users/update-profile`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: payload,
      });

      successToast("Profile updated successfully");
      onClose();
    } catch (error) {
      console.error("Error saving profile:", error);
    }
  };

  return (
    <div
      onClick={onClose}
      className="fixed inset-0 bg-[#000000b0] flex items-center justify-center"
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className="bg-white p-6 border-black border-[4px] rounded-lg shadow-lg max-w-[450px] w-full"
      >
        <h2 className="text-xl font-thin">Profile Settings</h2>

        {/* Color Picker */}
        <div className="flex items-center mt-4">
          <label className="mr-2">Name Color:</label>
          <input
            type="color"
            value={localNameColor}
            onChange={(e) => setLocalNameColor(e.target.value)}
          />
        </div>

        {/* Preset Profile Pictures */}
        <div className="mt-4">
          <label>Choose a Profile Picture:</label>
          <div className="grid grid-cols-3 gap-2 mt-2">
            {presetImages.map((img, index) => (
              <div
                key={index}
                className={`rounded-full flex items-center justify-center h-[100px] w-[100px] border-4 p-1 cursor-pointer ${
                  selectedPreset === index
                    ? "border-[#8bff25] bg-gradient-to-br"
                    : "border-gray-300"
                }`}
                onClick={() => handlePresetImageSelect(img, index)}
              >
                <img
                  src={img}
                  alt={`Preset ${index + 1}`}
                  className="w-16 h-16 object-cover rounded-full"
                />
              </div>
            ))}
          </div>
        </div>

        {/* Or Upload a New Profile Picture */}
        <div className="flex flex-col items-start gap-2 mt-4">
          <h3>Upload Picture:</h3>
          <input type="file" accept="image/*" onChange={handleImageUpload} />
        </div>

        <div className="flex mt-5 gap-2">
          <button
            onClick={onClose}
            className="bg-[#ccc] hover:bg-[#aaa] w-full border-[3px] rounded-md border-black p-2"
          >
            Cancel
          </button>

          <button
            onClick={saveSettings}
            className="bg-[#bbff80] hover:bg-[#8df632] w-full border-[3px] rounded-md border-black p-2"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
}

export default SettingsModal;

import degen from "../assets/degenerate-g.png";

const Loading = ({}) => {
  return (
    <div className="">
      <img src={degen} alt="" />
    </div>
  );
};

export default Loading;
